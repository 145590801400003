import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import Link from '@magalu/stereo-ui/atoms/Link';
import { themeGet } from '@magalu/stereo-ui-styles';
export var Container = styled.header(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Content = styled(Box).attrs(function (props) {
  return _extends({
    bg: 'background.primary',
    color: 'text.light'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  height: ", ";\n  padding: ", "px ", "px;\n  display: grid;\n  grid-template-columns: 1fr 2fr 1fr;\n  align-items: center;\n"])), function (props) {
  return themeGet('sizes.header')(props);
}, themeGet('space.3'), themeGet('space.4'));
export var Logo = styled(Link)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  justify-self: center;\n"])));