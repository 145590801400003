import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Text from '@magalu/stereo-ui/atoms/Text';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Link from '@magalu/stereo-ui/atoms/Link';
import Strip from '@magalu/stereo-ui/atoms/Strip';
import { Icon } from '@magalu/stereo-ui-icons';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container, ContentWrapper, LinkList, FooterSeparator, SectionCopyright, SectionEcommerceLink, SectionLinks, SectionLogo, SectionSocial, SectionsWrapper } from './BlogFooter.styles';
var BlogFooter = function BlogFooter(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data;
  var socialNetworks = staticProps.socialNetworks,
    ecommerceLink = staticProps.ecommerceLink;
  var _data$blogFooter = data.blogFooter,
    footerData = _data$blogFooter === void 0 ? {} : _data$blogFooter;
  var linkList = (footerData == null ? void 0 : footerData.linkList) || [];
  return React.createElement(Container, {
    "data-testid": "blog-footer-container"
  }, React.createElement(ContentWrapper, null, React.createElement(SectionLinks, null, React.createElement(LinkList, {
    length: linkList.length
  }, linkList == null ? void 0 : linkList.map(function (link) {
    return React.createElement("li", {
      key: link.text
    }, React.createElement(Link, {
      href: link.href,
      color: "inherit",
      fontSize: 2,
      lineHeight: 1.25
    }, link.text));
  }))), React.createElement(SectionsWrapper, null, React.createElement(SectionLogo, null, React.createElement(Icon, {
    name: "LogoBlogLu",
    color: "text.light",
    width: 200,
    height: 36
  })), ecommerceLink && React.createElement(SectionEcommerceLink, null, React.createElement(Link, {
    href: ecommerceLink.href
  }, React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "center",
    bg: "background.light",
    p: 3,
    borderRadius: "big",
    width: 230,
    height: 48
  }, React.createElement(Text, {
    color: "primary.dark",
    fontWeight: "bold",
    fontSize: 2
  }, ecommerceLink.label)))), React.createElement(FooterSeparator, null), React.createElement(SectionSocial, {
    "static": {
      links: socialNetworks == null ? void 0 : socialNetworks.links,
      theme: {
        iconColor: 'primary.dark'
      },
      title: socialNetworks == null ? void 0 : socialNetworks.title
    }
  })), React.createElement(SectionCopyright, null, React.createElement(Icon, {
    name: "LogoMagalu",
    color: "text.white",
    width: 120,
    height: 20,
    viewBox: "0 0 60 13"
  }), React.createElement(Box, {
    width: 85
  }, React.createElement(Strip, {
    size: 3,
    position: "relative"
  })))));
};
process.env.NODE_ENV !== "production" ? BlogFooter.propTypes = {
  data: shape({
    blogFooter: shape({
      linkList: arrayOf(shape({
        href: string,
        text: string
      }))
    })
  }),
  "static": shape({
    ecommerceLink: shape({
      href: string,
      label: string
    }),
    socialNetworks: shape({
      links: arrayOf(shape({
        alt: string,
        icon: string,
        url: string
      })),
      title: string
    })
  })
} : void 0;
BlogFooter.defaultProps = {
  data: {},
  "static": {}
};
BlogFooter.ssr = true;
BlogFooter.displayName = 'BlogFooter';
export default withLayoutProps(BlogFooter);