import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["logo", "basePath"];
import React from 'react';
import { shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import BreadcrumbModule from '@magalu/stereo-ui/molecules/Breadcrumb';
import { routePush } from '@magalu/mixer-utils';
import parseBreadCrumbBlog from './BlogBreadCrumb.utils';
var BlogBreadCrumb = function BlogBreadCrumb(_ref) {
  var structure = _ref.structure,
    _ref$static = _ref["static"],
    logo = _ref$static.logo,
    basePath = _ref$static.basePath,
    props = _objectWithoutProperties(_ref$static, _excluded),
    data = _ref.data;
  var id = structure.id;
  var handleLogoClick = function handleLogoClick(e) {
    e.preventDefault();
    var path = (logo == null ? void 0 : logo.url) || '/';
    routePush({
      path: path,
      spa: true
    });
  };
  var handleClickItem = function handleClickItem(e, item) {
    e.preventDefault();
    var url = item.url;
    routePush({
      path: url,
      spa: true
    });
  };
  var itens = parseBreadCrumbBlog(id, data);
  return React.createElement(Box, _extends({
    overflowX: "auto",
    width: "100%",
    overflowY: "hidden",
    paddingLeft: "2",
    "data-testid": "breadcrumb-container"
  }, props), React.createElement(BreadcrumbModule, {
    items: itens,
    domain: basePath,
    onClickItem: handleClickItem,
    onLogoClick: handleLogoClick,
    logo: logo,
    isTransparent: true
  }));
};
BlogBreadCrumb.ssr = true;
process.env.NODE_ENV !== "production" ? BlogBreadCrumb.propTypes = {
  data: shape({
    blogPostContent: shape({})
  }),
  "static": shape({
    logo: shape({
      icon: string.isRequired,
      styles: shape({}),
      title: string.isRequired,
      url: string.isRequired
    })
  }).isRequired,
  structure: shape({}).isRequired
} : void 0;
BlogBreadCrumb.defaultProps = {
  data: {}
};
export default BlogBreadCrumb;