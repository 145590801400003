export default (function (id) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var breadcrumb = [];
  var handlePageData = function handlePageData() {
    var _data$blogCategoryCon = data.blogCategoryContent,
      blogCategoryContent = _data$blogCategoryCon === void 0 ? {} : _data$blogCategoryCon,
      _data$blogPostContent = data.blogPostContent,
      blogPostContent = _data$blogPostContent === void 0 ? {} : _data$blogPostContent;
    switch (id) {
      case 'post':
        return blogPostContent;
      case 'category':
        return {
          category: blogCategoryContent
        };
      case 'subcategory':
        return {
          category: blogCategoryContent == null ? void 0 : blogCategoryContent.parentCategory,
          subcategory: blogCategoryContent
        };
      default:
        return {};
    }
  };
  var _handlePageData = handlePageData(),
    category = _handlePageData.category,
    subcategory = _handlePageData.subcategory,
    title = _handlePageData.title;
  if (category && (category == null ? void 0 : category.categoryId)) {
    var categoryBreadcrumbItem = {
      name: category.title,
      position: 0,
      url: "/blog-da-lu/l/".concat(category.categoryId.toLowerCase(), "/").concat(category.slug, "/").concat(category.id, "/")
    };
    breadcrumb.push(categoryBreadcrumbItem);
  }
  if (subcategory && (subcategory == null ? void 0 : subcategory.categoryId)) {
    var subCategoryBreadcrumbItem = {
      name: subcategory.title,
      position: 1,
      url: "/blog-da-lu/s/".concat(category.categoryId.toLowerCase(), "/").concat(subcategory.categoryId.toLowerCase(), "/").concat(subcategory.slug, "/").concat(subcategory.id, "/")
    };
    breadcrumb.push(subCategoryBreadcrumbItem);
  }
  if (id === 'post' && title) {
    var postBreadcrumbItem = {
      name: title,
      position: subcategory ? 2 : 1,
      url: 'slug'
    };
    breadcrumb.push(postBreadcrumbItem);
  }
  return breadcrumb;
});