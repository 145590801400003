import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Card from '@magalu/stereo-ui/atoms/Card';
import { getMediaQuery, themeGet } from '@magalu/stereo-ui-styles';
export var CardText = styled(Card.Text)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  height: 100px;\n"])));
export var Container = styled(Flex).attrs(function (props) {
  return _extends({}, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-direction: column;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  gap: ", "px;\n\n  ", " {\n    flex-direction: row;\n    gap: ", "px 4%;\n\n    > * {\n      flex-basis: 48%;\n    }\n  }\n\n  ", " {\n    flex-direction: row;\n    gap: ", "px 2%;\n\n    & > :last-child {\n      display: none;\n    }\n\n    > * {\n      flex-basis: 31.333%;\n    }\n  }\n\n  ", " {\n    flex-direction: row;\n    gap: ", "px 1%;\n\n    & > :last-child {\n      display: grid;\n    }\n\n    > * {\n      flex-basis: ", "%;\n    }\n  }\n"])), themeGet('space.4'), getMediaQuery('small'), themeGet('space.4'), getMediaQuery('medium'), themeGet('space.4'), getMediaQuery('large'), themeGet('space.4'), function (props) {
  return props.columns ? 96 / props.columns : 24;
});