import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["tags"];
import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container, Item, Link, List } from './BlogTagList.styles';
var BlogTagList = function BlogTagList(_ref) {
  var id = _ref.id,
    _ref$static = _ref["static"],
    _ref$static$tags = _ref$static.tags,
    tags = _ref$static$tags === void 0 ? [] : _ref$static$tags,
    props = _objectWithoutProperties(_ref$static, _excluded);
  var mountUrl = function mountUrl(item) {
    return "/blog-da-lu/t/".concat(item.slug, "/").concat(item.id, "/");
  };
  var handleTagClick = function handleTagClick(e, item) {
    e.preventDefault();
    routePush({
      path: mountUrl(item),
      spa: true
    });
  };
  return Boolean(tags == null ? void 0 : tags.length) && React.createElement(Container, _extends({
    "data-testid": "tag-list-container"
  }, props), React.createElement(List, {
    "data-testid": "tag-list-ul"
  }, tags.map(function (item, index) {
    return React.createElement(Item, {
      key: "".concat(id, "-").concat(index + 1),
      "data-testid": "tag-list-text",
      onClick: function onClick(e) {
        return handleTagClick(e, item);
      }
    }, React.createElement(Link, {
      alt: item.title,
      href: mountUrl(item),
      onClick: function onClick(e) {
        return handleTagClick(e, item);
      }
    }, item.title));
  })));
};
process.env.NODE_ENV !== "production" ? BlogTagList.propTypes = {
  id: string,
  "static": shape({
    items: arrayOf(shape({
      id: string,
      slug: string.isRequired,
      title: string.isRequired
    }))
  })
} : void 0;
BlogTagList.defaultProps = {
  id: 'taglist',
  "static": {}
};
BlogTagList.ssr = true;
export default withLayoutProps(BlogTagList);