import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import LinkElement from '@magalu/stereo-ui/atoms/Link';
export var Container = styled(Box).attrs(function (props) {
  return _extends({
    borderRadius: 'default',
    textAlign: 'left'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Item = styled(Box).attrs(function () {
  return {
    backgroundColor: 'background.lightPurple',
    borderRadius: 'default',
    fontSize: '2',
    fontWeight: 'medium',
    forwardedAs: 'li',
    lineHeight: 'big',
    mr: 3,
    my: 2,
    px: 2,
    py: 1
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  display: inline-block;\n\n  & a {\n    display: inline-block;\n    line-height: normal;\n  }\n"])));
export var Link = styled(LinkElement).attrs(function () {
  return {
    color: 'text.primary',
    fontSize: 2,
    fontWeight: 'medium',
    underlineOnHover: false
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var List = styled.ul(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  list-style-position: inside;\n  justify-content: space-between;\n  display: inline;\n  font-size: inherit;\n  line-height: normal;\n"])));