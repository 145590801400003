import _toArray from "@babel/runtime/helpers/esm/toArray";
import React from 'react';
import { arrayOf, shape, string, oneOf } from 'prop-types';

import Card from '@magalu/stereo-ui/atoms/Card';
import Text from '@magalu/stereo-ui/atoms/Text';
import Grid from '@magalu/stereo-ui/atoms/Grid';

import { routePush, parseDateTime } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';

import { Container } from './Highlight.styles';
function Highlight(_ref) {
  var _highlightedPost$imag, _highlightedPost$imag2, _parseDateTime;
  var posts = _ref.posts,
    cardTheme = _ref.cardTheme,
    titleAs = _ref.titleAs,
    viewport = _ref.viewport,
    customBasePath = _ref.customBasePath;
  var _posts = _toArray(posts),
    highlightedPost = _posts[0],
    otherPosts = _posts.slice(1);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handlePostClick = function handlePostClick(categoryId, title, e, slug, postId) {
    e.preventDefault();
    var recomendationLayout = 'Highlight';
    routePush({
      path: "".concat(customBasePath, "/blog-da-lu/c/").concat(slug, "/").concat(postId, "/"),
      spa: true
    });
    publish('blogpost:click', {
      categoryId: categoryId,
      recomendationLayout: recomendationLayout,
      title: title
    });
  };
  return React.createElement(Container, {
    "data-testid": "highlight-variation"
  }, React.createElement(Grid, {
    width: "100%",
    gridArea: "highlighted"
  }, React.createElement(Card, {
    key: highlightedPost.id,
    as: "a",
    href: "".concat(customBasePath, "/blog-da-lu/c/").concat(highlightedPost.slug, "/").concat(highlightedPost.id, "/"),
    backgroundColor: cardTheme,
    enableHover: true,
    gridTemplateRows: [null, null, 'auto 1fr'],
    gridTemplateColumns: "1fr",
    "data-testid": "main-highlight-card",
    onClick: function onClick(e) {
      return handlePostClick(highlightedPost.categoryId, highlightedPost.title, e, highlightedPost.slug, highlightedPost.id);
    }
  }, React.createElement(Card.Image, {
    src: "".concat((_highlightedPost$imag = highlightedPost.image) == null ? void 0 : (_highlightedPost$imag2 = _highlightedPost$imag.urls) == null ? void 0 : _highlightedPost$imag2["default"], "?ims=fit-in/{w}x"),
    srcSetDensity: viewport === 'mobile' ? [1, 1.5, 2] : [],
    width: viewport === 'mobile' ? 380 : 630,
    objectFit: "cover",
    imageHeight: ['320px', '100%', '420px']
  }), React.createElement(Card.Body, null, React.createElement(Card.Title, {
    forwardedAs: titleAs,
    truncate: true,
    lines: 2,
    fontSize: [5]
  }, highlightedPost.title), React.createElement(Card.Subtitle, {
    fontSize: [null, 1, 1]
  }, React.createElement(Text, {
    fontWeight: "bold",
    color: "inherit",
    display: "inline-block"
  }, highlightedPost.categoryTitle), Boolean(highlightedPost.updatedAt) && " | ".concat((_parseDateTime = parseDateTime(highlightedPost.updatedAt)) == null ? void 0 : _parseDateTime.getShortMonthYear)), React.createElement(Card.Text, {
    truncate: true,
    lines: 4
  }, highlightedPost.subtitle)))), React.createElement(Grid, {
    gridArea: "listing",
    rowGap: 20,
    gridTemplateRows: "repeat(3, min-content)"
  }, otherPosts == null ? void 0 : otherPosts.map(function (_ref2) {
    var _image$urls, _parseDateTime2;
    var id = _ref2.id,
      image = _ref2.image,
      title = _ref2.title,
      categoryId = _ref2.categoryId,
      categoryTitle = _ref2.categoryTitle,
      updatedAt = _ref2.updatedAt,
      slug = _ref2.slug;
    return React.createElement(Card, {
      key: id,
      as: "a",
      href: "".concat(customBasePath, "/blog-da-lu/c/").concat(slug, "/").concat(id, "/"),
      backgroundColor: "background.base",
      enableHover: true,
      "data-testid": "highlight-card",
      gridTemplateAreas: "'image body'",
      gridTemplateColumns: ['180px 1fr', '250px 1fr', '250px 1fr'],
      borderRadius: "12px",
      onClick: function onClick(e) {
        return handlePostClick(categoryId, title, e, slug, id);
      }
    }, React.createElement(Card.Image, {
      imageWidth: [null, '250px'],
      imageHeight: ['127px', '192px'],
      objectFit: "cover",
      borderRadius: 6,
      src: "".concat(image == null ? void 0 : (_image$urls = image.urls) == null ? void 0 : _image$urls["default"], "?ims=fit-in/{w}x"),
      alt: image.alt,
      srcSetDensity: viewport === 'mobile' ? [1, 1.5, 2] : [],
      width: viewport === 'mobile' ? 180 : 250
    }), React.createElement(Card.Body, null, React.createElement(Card.Title, {
      forwardedAs: titleAs,
      truncate: true,
      lines: 2,
      fontSize: 3
    }, title), React.createElement(Card.Subtitle, {
      truncate: true,
      lines: 1,
      fontSize: 1
    }, React.createElement(Text, {
      fontWeight: "bold",
      color: "inherit",
      display: "inline-block"
    }, categoryTitle), Boolean(updatedAt) && " | ".concat((_parseDateTime2 = parseDateTime(updatedAt)) == null ? void 0 : _parseDateTime2.getShortMonthYear))));
  })));
}
process.env.NODE_ENV !== "production" ? Highlight.propTypes = {
  cardTheme: string,
  customBasePath: string,
  posts: arrayOf(shape({})).isRequired,
  titleAs: string,
  viewport: oneOf(['mobile', 'desktop'])
} : void 0;
Highlight.defaultProps = {
  cardTheme: 'primary.dark',
  customBasePath: '',
  titleAs: 'h3',
  viewport: 'mobile'
};
export default Highlight;