import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import TextComponent from '@magalu/stereo-ui/atoms/Text';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import Box from '@magalu/stereo-ui/atoms/Box';
import { getMediaQuery, themeGet } from '@magalu/stereo-ui-styles';
export var Container = styled(Grid)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: 768px;\n  grid-template-areas:\n    'category-area first-divider published-date-area'\n    'reading-time-area reading-time-area reading-time-area';\n\n  ", " {\n    grid-template-areas: 'category-area first-divider published-date-area second-divider reading-time-area';\n  }\n"])), getMediaQuery('small'));
export var Divider = styled(Box).attrs(function () {
  return {
    forwardedAs: 'span'
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-top: ", "px;\n  grid-area: ", ";\n  border-radius: ", ";\n  width: 2px;\n  height: 2px;\n  display: ", ";\n  ", " {\n    display: block;\n  }\n"])), themeGet('space.3'), function (_ref) {
  var gridArea = _ref.gridArea;
  return gridArea;
}, themeGet('radii.circle'), function (_ref2) {
  var gridArea = _ref2.gridArea;
  return gridArea === 'second-divider' ? 'none' : 'block';
}, getMediaQuery('small'));
export var Text = styled(TextComponent)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: ", "px 0;\n  grid-area: ", ";\n"])), themeGet('space.2'), function (_ref3) {
  var gridArea = _ref3.gridArea;
  return gridArea;
});