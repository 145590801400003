import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Collapse from '@magalu/stereo-ui/atoms/Collapse';
import List from '@magalu/stereo-ui/molecules/List';
import Text from '@magalu/stereo-ui/atoms/Text';
import { Icon, Close } from '@magalu/stereo-ui-icons';
import Strip from '@magalu/stereo-ui/atoms/Strip';
import IconButton from '@magalu/stereo-ui/molecules/IconButton';
import { uniqueId } from 'lodash';
import BlogEcommerceLink from '../../BlogEcommerceLink';
import { Container, Content, Logo, SectionHeader, SidebarFooter, SidebarHeader } from './BlogSidebarMenu.styles';
var BlogSidebarMenu = function BlogSidebarMenu(_ref) {
  var ecommerceLink = _ref.ecommerceLink,
    linkGroups = _ref.linkGroups,
    onCloseMenuClick = _ref.onCloseMenuClick,
    onLogoClick = _ref.onLogoClick,
    onMenuItemClick = _ref.onMenuItemClick;
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    openGroups = _useState2[0],
    setOpenGroups = _useState2[1];
  return React.createElement(Container, {
    "data-testid": "blog-menu-container"
  }, React.createElement(SectionHeader, null, React.createElement(SidebarHeader, null, React.createElement(IconButton, {
    name: "Close",
    onClick: onCloseMenuClick
  }, React.createElement(Close, {
    role: "button",
    "aria-label": "fechar o menu",
    "data-testid": "btn-close-menu",
    color: "text.light",
    width: 20,
    height: 20
  })), React.createElement(Logo, {
    onClick: onLogoClick,
    role: "link",
    "data-testid": "sidebar-logo",
    "aria-label": "ir para p\xE1gina inicial"
  }, React.createElement(Icon, {
    name: "LogoBlogLu",
    color: "text.light",
    width: 150,
    height: 27
  }))), React.createElement(Strip, {
    position: "relative"
  })), React.createElement(Content, null, Boolean(linkGroups == null ? void 0 : linkGroups.length) && React.createElement(React.Fragment, null, React.createElement(Text, {
    fontWeight: "bold",
    fontSize: 3
  }, "Assuntos"), React.createElement(Box, {
    bg: "background.light",
    borderRadius: "big",
    marginBottom: 10,
    marginTop: 10
  }, React.createElement(List, {
    divided: true
  }, linkGroups == null ? void 0 : linkGroups.map(function (_ref2, groupIndex) {
    var label = _ref2.label,
      linkList = _ref2.linkList;
    return React.createElement(React.Fragment, null, React.createElement(List.Item, {
      "data-testid": "blog-menu-group-item",
      key: "link-group-".concat(uniqueId(label)),
      fill: "primary",
      onClick: function onClick() {
        setOpenGroups(function (currentGroups) {
          var currentState = currentGroups[groupIndex] || false;
          return _extends({}, currentGroups, _defineProperty({}, groupIndex, !currentState));
        });
      },
      endIcon: openGroups[groupIndex] ? 'ChevronUp' : 'ChevronDown'
    }, label), React.createElement(Collapse, {
      trigger: openGroups[groupIndex]
    }, linkList.map(function (item) {
      return React.createElement(List.Item, {
        "data-testid": "blog-menu-link-item",
        as: "a",
        key: "link-item-".concat(uniqueId(label), "-").concat(uniqueId(item.value)),
        alt: item.alt,
        inset: true,
        color: "text.primary",
        href: item.href,
        onClick: function onClick(e) {
          return onMenuItemClick(e, item);
        }
      }, item.value);
    })));
  }))))), React.createElement(SidebarFooter, null, React.createElement(BlogEcommerceLink, ecommerceLink)));
};
process.env.NODE_ENV !== "production" ? BlogSidebarMenu.propTypes = {
  ecommerceLink: shape({
    href: string,
    label: string
  }).isRequired,
  linkGroups: arrayOf(shape({
    label: string,
    linkList: arrayOf(shape({
      alt: string,
      href: string,
      value: string
    }))
  })),
  onCloseMenuClick: func.isRequired,
  onLogoClick: func.isRequired,
  onMenuItemClick: func.isRequired
} : void 0;
BlogSidebarMenu.defaultProps = {
  linkGroups: []
};
export default BlogSidebarMenu;