import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import Link from '@magalu/stereo-ui/atoms/Link';
import { themeGet } from '@magalu/stereo-ui-styles';
export var Container = styled(Grid).attrs(function () {
  return {
    backgroundColor: 'background.base',
    height: '100vh'
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  grid-template-rows: 80px 1fr 80px;\n"])));
export var Content = styled(Box).attrs(function () {
  return {
    mx: 3,
    my: 4
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var Logo = styled(Link)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  justify-self: left;\n"])));
export var SectionHeader = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var SidebarFooter = styled(Flex).attrs(function (props) {
  return _extends({
    bg: 'background.light'
  }, props);
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  height: ", ";\n  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);\n  justify-content: center;\n"])), function (props) {
  return themeGet('sizes.header')(props);
});
export var SidebarHeader = styled(Box).attrs(function (props) {
  return _extends({
    bg: 'background.primary',
    color: 'text.light'
  }, props);
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  height: ", ";\n  padding: ", "px;\n  padding-bottom: ", "px;\n  display: grid;\n  justify-items: right;\n"])), function (props) {
  return themeGet('sizes.header')(props);
}, themeGet('space.2'), themeGet('space.4'));