export var parseShareLinks = function parseShareLinks() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _data$url = data.url,
    url = _data$url === void 0 ? '' : _data$url,
    _data$title = data.title,
    title = _data$title === void 0 ? '' : _data$title,
    _data$domain = data.domain,
    domain = _data$domain === void 0 ? '' : _data$domain;
  var txtUrl = "".concat(domain, "/").concat(url);
  var txtTitle = encodeURIComponent(title);
  return {
    urlFacebook: "https://www.facebook.com/sharer/sharer.php?u=".concat(txtUrl),
    urlLinkedIn: "https://www.linkedin.com/sharing/share-offsite/?url=".concat(txtUrl),
    urlTwitter: "https://twitter.com/intent/tweet?text=".concat(txtTitle, "&url=").concat(txtUrl),
    urlWhatsApp: "https://wa.me/?text=".concat(txtUrl)
  };
};