import _typeof from "@babel/runtime/helpers/esm/typeof";
import _objectDestructuringEmpty from "@babel/runtime/helpers/esm/objectDestructuringEmpty";
import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape } from 'prop-types';
import { readingTimeEstimator, sanitizeHtml, parseDateTime } from '@magalu/mixer-utils';
import { Container, Divider, Text } from './BlogPostMetaData.styles';
var BlogPostMetaData = function BlogPostMetaData(_ref) {
  var data = _ref.data,
    props = _extends({}, (_objectDestructuringEmpty(_ref["static"]), _ref["static"]));
  var _ref2 = (data == null ? void 0 : data.blogPostContent) || {},
    subcategory = _ref2.subcategory,
    category = _ref2.category,
    content = _ref2.content,
    updatedAt = _ref2.updatedAt;
  var rawPostContent = sanitizeHtml(content, {
    rawText: true
  });
  var _readingTimeEstimator = readingTimeEstimator(rawPostContent),
    minutes = _readingTimeEstimator.minutes;
  var _parseDateTime = parseDateTime(updatedAt),
    getShortDate = _parseDateTime.getShortDate;
  return _typeof(data == null ? void 0 : data.blogPostContent) === 'object' && Boolean(Object.keys(data == null ? void 0 : data.blogPostContent).length) && React.createElement(Container, props, React.createElement(Text, {
    color: "primary.dark",
    fontWeight: "bold",
    gridArea: "category-area",
    "data-testid": "category-metadata"
  }, (subcategory == null ? void 0 : subcategory.title) || (category == null ? void 0 : category.title)), React.createElement(Divider, {
    gridArea: "first-divider",
    backgroundColor: "primary.dark"
  }), React.createElement(Text, {
    gridArea: "published-date-area",
    "data-testid": "date-metadata"
  }, "Atualizado em", " ", getShortDate), React.createElement(Divider, {
    gridArea: "second-divider",
    backgroundColor: "primary.dark"
  }), React.createElement(Text, {
    gridArea: "reading-time-area",
    "data-testid": "reading-time-metadata"
  }, minutes, " ", "min de leitura"));
};
BlogPostMetaData.ssr = true;
process.env.NODE_ENV !== "production" ? BlogPostMetaData.propTypes = {
  data: shape({}),
  "static": shape({})
} : void 0;
BlogPostMetaData.defaultProps = {
  data: {},
  "static": {}
};
export default BlogPostMetaData;