import _extends from "@babel/runtime/helpers/esm/extends";
import randomLinksHeader from './randomLinksHeader';
var parseHeaderLinksSuggestions = function parseHeaderLinksSuggestions() {
  var _linksSuggestionsData;
  var linksSuggestionsData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if ((linksSuggestionsData == null ? void 0 : (_linksSuggestionsData = linksSuggestionsData.links) == null ? void 0 : _linksSuggestionsData.length) > 1 && (linksSuggestionsData == null ? void 0 : linksSuggestionsData.title) && (linksSuggestionsData == null ? void 0 : linksSuggestionsData.listSize) && (linksSuggestionsData == null ? void 0 : linksSuggestionsData.maxChars)) {
    return {
      linksSuggestions: _extends({}, linksSuggestionsData, {
        links: randomLinksHeader(linksSuggestionsData, linksSuggestionsData.listSize, linksSuggestionsData.maxChars)
      })
    };
  }
  return {};
};
export default parseHeaderLinksSuggestions;