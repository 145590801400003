import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import Strip from '@magalu/stereo-ui/atoms/Strip';
import IconButton from '@magalu/stereo-ui/molecules/IconButton';
import Sidebar from '@magalu/stereo-ui/organisms/Sidebar';
import { Burger, LogoBlogLu } from '@magalu/stereo-ui-icons';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container, Content, Logo } from './BlogHeader.styles';
import BlogSidebarMenu from './BlogSidebarMenu';
var BlogHeader = function BlogHeader(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data;
  var _data$blogHeader = data.blogHeader,
    headerData = _data$blogHeader === void 0 ? {} : _data$blogHeader;
  var linksGroups = (headerData == null ? void 0 : headerData.LinksGroup) || [];
  var ecommerceLink = staticProps.ecommerceLink,
    logo = staticProps.logo;
  var _ref2 = (headerData == null ? void 0 : headerData.linkList) || [],
    linkList = _ref2.linkList;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var handleMenuItemClick = function handleMenuItemClick(e, item) {
    e.preventDefault();
    setOpen(false);
    routePush({
      path: item.href,
      spa: true
    });
  };
  var handleLogoClick = function handleLogoClick(e) {
    e.preventDefault();
    routePush({
      path: logo.url,
      spa: true
    });
  };
  return React.createElement(Container, {
    "data-testid": "blog-header-container"
  }, React.createElement(Content, null, React.createElement(IconButton, {
    name: "Burger",
    onClick: function onClick() {
      return setOpen(!open);
    }
  }, React.createElement(Burger, {
    role: "button",
    "aria-label": "abrir o menu",
    "data-testid": "button-burger",
    color: "#fff"
  })), React.createElement(Logo, {
    onClick: handleLogoClick,
    href: logo.url,
    role: "link",
    "data-testid": "logo",
    "aria-label": "ir para p\xE1gina inicial"
  }, React.createElement(LogoBlogLu, {
    color: "text.light",
    width: 150,
    height: 27
  }))), React.createElement(Strip, {
    position: "relative"
  }), React.createElement(Sidebar, {
    visible: open,
    bg: "base",
    onClickOutside: function onClickOutside() {
      return setOpen(false);
    }
  }, React.createElement(BlogSidebarMenu, {
    linkList: linkList,
    linkGroups: linksGroups,
    onCloseMenuClick: function onCloseMenuClick() {
      return setOpen(false);
    },
    onMenuItemClick: handleMenuItemClick,
    onLogoClick: handleLogoClick,
    ecommerceLink: ecommerceLink
  })));
};
process.env.NODE_ENV !== "production" ? BlogHeader.propTypes = {
  data: shape({
    blogHeader: shape({
      LinksGroup: arrayOf(shape({
        label: string,
        linkList: arrayOf(shape({
          alt: string,
          href: string,
          value: string
        }))
      }))
    })
  }),
  "static": shape({
    ecommerceLink: shape({
      href: string,
      label: string
    }).isRequired,
    logo: shape({
      url: string.isRequired
    }).isRequired
  })
} : void 0;
BlogHeader.defaultProps = {
  data: {},
  "static": {}
};
BlogHeader.ssr = true;
BlogHeader.displayName = 'BlogHeader';
export default withLayoutProps(BlogHeader);