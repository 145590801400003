import React from 'react';
import { arrayOf, shape, string, oneOf } from 'prop-types';

import Carousel from '@magalu/stereo-ui/molecules/Carousel';
import Card from '@magalu/stereo-ui/atoms/Card';
import Text from '@magalu/stereo-ui/atoms/Text';

import { routePush, parseDateTime } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
function BannerCarousel(_ref) {
  var posts = _ref.posts,
    cardTheme = _ref.cardTheme,
    viewport = _ref.viewport,
    titleAs = _ref.titleAs,
    customBasePath = _ref.customBasePath;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handlePostClick = function handlePostClick(categoryId, e, title, slug, postId) {
    e.preventDefault();
    var recomendationLayout = 'BannerCarousel';
    routePush({
      path: "".concat(customBasePath, "/blog-da-lu/c/").concat(slug, "/").concat(postId, "/"),
      spa: true
    });
    publish('blogpost:click', {
      categoryId: categoryId,
      recomendationLayout: recomendationLayout,
      title: title
    });
  };
  return React.createElement(Carousel, {
    "data-testid": "banner-carousel-variation",
    auto: false,
    duration: 400,
    gutter: 15,
    interval: 3000,
    insideControls: true,
    showPartialNextSlide: viewport === 'mobile',
    showArrowControls: viewport === 'desktop'
  }, posts == null ? void 0 : posts.map(function (_ref2) {
    var _image$urls, _parseDateTime;
    var id = _ref2.id,
      image = _ref2.image,
      title = _ref2.title,
      categoryTitle = _ref2.categoryTitle,
      categoryId = _ref2.categoryId,
      updatedAt = _ref2.updatedAt,
      subtitle = _ref2.subtitle,
      slug = _ref2.slug;
    return React.createElement(Card, {
      key: id,
      as: "a",
      href: "".concat(customBasePath, "/blog-da-lu/c/").concat(slug, "/").concat(id, "/"),
      backgroundColor: cardTheme,
      enableHover: true,
      "data-testid": "banner-carousel-card",
      gridTemplateAreas: ["'image' 'body'", "'image' 'body'", "'image body'"],
      gridTemplateColumns: ['1fr', '1fr', '3fr 1fr'],
      gridTemplateRows: ['300px 1fr', '400px 1fr', '500px 1fr'],
      height: ['500px', '600px', 'auto'],
      onClick: function onClick(e) {
        return handlePostClick(categoryId, e, title, slug, id);
      }
    }, React.createElement(Card.Image, {
      src: "".concat(image == null ? void 0 : (_image$urls = image.urls) == null ? void 0 : _image$urls["default"], "?ims=fit-in/{w}x"),
      alt: image.alt,
      srcSetDensity: viewport === 'mobile' ? [1, 1.5, 2] : [],
      width: viewport === 'mobile' ? 346 : 960,
      objectFit: "cover"
    }), React.createElement(Card.Body, null, React.createElement(Card.Title, {
      forwardedAs: titleAs,
      truncate: true,
      lines: viewport === 'desktop' ? 4 : 2,
      fontSize: [5, 6, 8]
    }, title), React.createElement(Card.Subtitle, {
      fontSize: [2, 2, 2]
    }, Boolean(updatedAt) && "".concat((_parseDateTime = parseDateTime(updatedAt)) == null ? void 0 : _parseDateTime.getShortMonthYear, " | "), React.createElement(Text, {
      fontWeight: "bold",
      color: "inherit",
      display: "inline-block"
    }, categoryTitle)), React.createElement(Card.Text, {
      truncate: true,
      lines: 4,
      fontSize: [2, 2, 3]
    }, subtitle)));
  }));
}
process.env.NODE_ENV !== "production" ? BannerCarousel.propTypes = {
  cardTheme: string,
  customBasePath: string,
  posts: arrayOf(shape({})).isRequired,
  titleAs: string,
  viewport: oneOf(['mobile', 'desktop'])
} : void 0;
BannerCarousel.defaultProps = {
  cardTheme: 'background.light',
  customBasePath: '',
  titleAs: 'h3',
  viewport: 'mobile'
};
export default BannerCarousel;