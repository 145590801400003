import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import PaginationComponent from '@magalu/stereo-ui/molecules/Pagination';
import Box from '@magalu/stereo-ui/atoms/Box';
export var Content = styled(Box).attrs(function (props) {
  return _extends({
    px: 3
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Pagination = styled(PaginationComponent)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  > ul {\n    margin-top: 60px;\n  }\n  > ul > li > a {\n    font-weight: 600;\n    font-size: 18px;\n    width: 40.63px;\n    height: 40px;\n    border: 1px solid #808080;\n    border-radius: 8px;\n    color: #808080;\n  }\n\n  > ul > li > a[aria-current='true'] {\n    color: #fff;\n  }\n\n  > ul > li > button {\n    align-items: center;\n    justify-content: center;\n    width: 40.63px;\n    height: 40px;\n    border: 1px solid #808080;\n    border-radius: 8px;\n  }\n"])));