import _extends from "@babel/runtime/helpers/esm/extends";
import React, { useEffect, useRef } from 'react';
import { arrayOf, oneOf, shape, string, bool } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { getCurrentPath, scrollToRef } from '@magalu/mixer-utils';
import BlogRecommendation from '../BlogRecommendation';
import { useBlogPostsList } from '@magalu/mixer-graphql';
function BlogPostsList(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data,
    structure = _ref.structure;
  var title = staticProps.title,
    theme = staticProps.theme;
  var anchorRef = useRef(null);
  var path = getCurrentPath();
  var page = (path == null ? void 0 : path.split('=')[1]) || [];
  var posts = staticProps.csr ? useBlogPostsList({
    limit: 4
  }).posts : staticProps.posts || data.blogPostsListing.posts;
  useEffect(function () {
    if (page > 0) {
      scrollToRef(anchorRef);
    }
  }, [page]);
  return React.createElement(React.Fragment, null, React.createElement("span", {
    ref: anchorRef
  }), React.createElement(BlogRecommendation, {
    "static": _extends({
      recommendations: [{
        posts: posts,
        theme: theme,
        title: title,
        variation: 'list'
      }]
    }, staticProps),
    structure: structure
  }));
}
process.env.NODE_ENV !== "production" ? BlogPostsList.propTypes = {
  data: shape({
    blogPostsList: arrayOf(shape({}))
  }),
  "static": shape({
    csr: bool,
    theme: shape({
      background: string,
      card: string
    }),
    title: string.isRequired,
    viewport: oneOf(['mobile', 'desktop'])
  }),
  structure: shape({})
} : void 0;
BlogPostsList.defaultProps = {
  data: {},
  "static": {},
  structure: {}
};
BlogPostsList.dataSource = {
  blogPostsListing: {
    query: 'BlogPostsListQuery'
  }
};
BlogPostsList.ssr = true;
export default withLayoutProps(BlogPostsList);