import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
import styled from 'styled-components';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Box from '@magalu/stereo-ui/atoms/Box';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import { color, getMediaQuery, getPaletteColor, themeGet } from '@magalu/stereo-ui-styles';
import BlogShareActions from '../BlogShareActions';
export var Container = styled(Flex).attrs(function (props) {
  return _extends({
    bg: 'background.primary',
    color: 'text.light'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  justify-content: center;\n  width: 100%;\n\n  border-top-style: solid;\n  border-top-color: ", ";\n  border-top-width: ", "px;\n\n  ", " {\n    border-top-width: 40px;\n  }\n\n  ", "\n"])), function (props) {
  return getPaletteColor('primary.light')(props);
}, themeGet('space.2'), getMediaQuery('medium'), color);
export var ContentWrapper = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-direction: column;\n  width: 100%;\n  row-gap: ", "px;\n\n  max-width: 360px;\n  padding-top: ", "px;\n  padding-bottom: ", "px;\n  padding-right: ", "px;\n  padding-left: ", "px;\n\n  ", " {\n    max-width: 1400px;\n    padding-top: ", "px;\n    padding-bottom: ", "px;\n  }\n"])), themeGet('space.5'), themeGet('space.4'), themeGet('space.5'), themeGet('space.3'), themeGet('space.3'), getMediaQuery('medium'), themeGet('space.6'), themeGet('space.4'));
export var FooterSeparator = styled.hr(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  grid-area: separator;\n  height: 1px;\n  width: 60px;\n\n  ", " {\n    display: none;\n  }\n"])), getMediaQuery('medium'));
export var LinkList = styled(Grid).attrs(function () {
  return {
    fontWeight: 'medium',
    forwardedAs: 'ul',
    gridColumnGap: 5,
    gridRowGap: 3
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  grid-auto-flow: column;\n  grid-auto-columns: 1fr;\n  text-align: left;\n  grid-template-rows: ", ";\n\n  ", " {\n    grid-template-rows: repeat(5, 1fr);\n  }\n"])), function (_ref) {
  var _ref$length = _ref.length,
    length = _ref$length === void 0 ? 0 : _ref$length;
  return "repeat(".concat(Math.round(length / 2), ", 1fr)");
}, getMediaQuery('medium'));
export var SectionCopyright = styled(Flex)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  flex-direction: column;\n  align-items: center;\n"])));
export var SectionEcommerceLink = styled(Flex)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  grid-area: ecommercelink;\n  justify-content: center;\n"])));
export var SectionLinks = styled(Flex)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  justify-content: center;\n"])));
export var SectionLogo = styled(Box)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  grid-area: logo;\n  display: none;\n\n  ", " {\n    display: block;\n  }\n"])), getMediaQuery('medium'));
export var SectionSocial = styled(BlogShareActions)(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  grid-area: social;\n  flex-direction: column;\n  align-items: center;\n\n  ", " {\n    align-items: start;\n  }\n"])), getMediaQuery('medium'));
export var SectionsWrapper = styled(Grid)(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  grid-template-areas: 'ecommercelink' 'separator' 'social';\n  row-gap: ", "px;\n  justify-items: center;\n\n  ", " {\n    grid-template-areas: 'logo ecommercelink social';\n    grid-template-columns: repeat(3, 1fr);\n    align-items: end;\n    justify-items: center;\n  }\n"])), themeGet('space.4'), getMediaQuery('medium'));