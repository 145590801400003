import React from 'react';
import { shape } from 'prop-types';
import { parsePageFilter, getCurrentPath, routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Pagination, Content } from './BlogPagination.styles';
var BlogPagination = function BlogPagination(_ref) {
  var structure = _ref.structure,
    staticProps = _ref["static"];
  var canonicalPath = structure.canonicalPath,
    query = structure.query;
  var _onChange = function onChange(e, page) {
    e.preventDefault();
    var path = getCurrentPath({
      page: page
    });
    routePush({
      path: path,
      spa: true
    });
  };
  var _ref2 = staticProps || {},
    pagination = _ref2.pagination;
  var currentPage = query.page;
  return Boolean((pagination == null ? void 0 : pagination.pages) > 1) && React.createElement(Content, null, React.createElement(Pagination, {
    count: pagination == null ? void 0 : pagination.pages,
    page: parsePageFilter(currentPage),
    path: canonicalPath,
    onChange: function onChange(e, page) {
      return _onChange(e, page);
    }
  }));
};
process.env.NODE_ENV !== "production" ? BlogPagination.propTypes = {
  "static": shape({
    pagination: shape({})
  }),
  structure: shape({})
} : void 0;
BlogPagination.defaultProps = {
  "static": {},
  structure: {}
};
BlogPagination.ssr = true;
export default withLayoutProps(BlogPagination);