import _extends from "@babel/runtime/helpers/esm/extends";
import { BannerCarousel } from './BannerCarousel';
import { Carousel } from './Carousel';
import { Highlight } from './Highlight';
import { List } from './List';
import { VerticalList } from './VerticalList';
var backgroundVariations = {
  dark: 'background.primary',
  light: 'background.base'
};
var setGutter = function setGutter(gutter) {
  return gutter ? 3 : 0;
};
var variations = {
  bannerCarousel: {
    component: BannerCarousel,
    disableBackground: false,
    displaySubtitle: true,
    marginTop: [4, 4, 5],
    paddingBottom: 0,
    paddingX: setGutter(false)
  },
  carousel: {
    component: Carousel,
    disableBackground: false,
    displaySubtitle: true,
    marginTop: [4, 4, 5],
    paddingBottom: 0,
    paddingX: setGutter(true)
  },
  highlight: {
    component: Highlight,
    disableBackground: true,
    displaySubtitle: true,
    marginTop: [4, 4, 5],
    paddingBottom: 0,
    paddingX: setGutter(true)
  },
  list: {
    component: List,
    disableBackground: false,
    displaySubtitle: true,
    marginTop: [4, 4, 5],
    paddingX: setGutter(true)
  },
  verticalList: {
    component: VerticalList,
    disableBackground: false,
    displaySubtitle: true,
    paddingX: setGutter(false)
  }
};
export var getVariation = function getVariation(variation) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _ref$backgroundColorT = _ref.backgroundColorToken,
    backgroundColorToken = _ref$backgroundColorT === void 0 ? '' : _ref$backgroundColorT,
    _ref$cardColorTheme = _ref.cardColorTheme,
    cardColorTheme = _ref$cardColorTheme === void 0 ? '' : _ref$cardColorTheme,
    _ref$overrideVariatio = _ref.overrideVariations,
    overrideVariations = _ref$overrideVariatio === void 0 ? {} : _ref$overrideVariatio,
    viewport = _ref.viewport;
  var settings = _extends({}, variations[variation] || variations.list, overrideVariations[variation]);

  var backgroundColor = backgroundVariations[backgroundColorToken] || backgroundVariations.dark;

  var paddingY = backgroundColorToken === 'dark' && !(settings == null ? void 0 : settings.disableBackground) ? 5 : 3;

  var cardColor = backgroundColorToken === 'light' && cardColorTheme === 'dark' || (settings == null ? void 0 : settings.disableBackground) ? "card.darkest" : "card.".concat(cardColorTheme || 'light');

  var paddingXTitle = !(settings == null ? void 0 : settings.paddingX) && viewport === 'mobile' ? setGutter(true) : 0;
  return _extends({
    backgroundColor: backgroundColor,
    cardColor: cardColor,
    paddingXTitle: paddingXTitle,
    paddingY: paddingY,
    setGutter: setGutter
  }, settings);
};