import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
import styled from 'styled-components';
import { getMediaQuery, getTextColor, themeGet, getBackgroundColor } from '@magalu/stereo-ui-styles';
import Box from '@magalu/stereo-ui/atoms/Box';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import LinkElement from '@magalu/stereo-ui/atoms/Link';
export var Container = styled(Box).attrs(function (props) {
  return _extends({
    p: 3
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Content = styled(Box).attrs(function (props) {
  return _extends({
    borderRadius: 'default',
    textAlign: 'left'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  max-width: 1366px;\n  margin: 0 auto;\n"])));
export var Item = styled(Box).attrs(function () {
  return {
    fontWeight: 'medium',
    forwardedAs: 'li',
    lineHeight: 'big',
    my: 2,
    px: 2,
    py: 1
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  border-radius: ", ";\n  display: inline-block;\n  margin-right: ", "px;\n  font-size: ", "px;\n  border: 1px solid ", ";\n  & a {\n    display: inline-block;\n    line-height: normal;\n  }\n"])), function (props) {
  return themeGet('radii.default')(props);
}, themeGet('space.3'), themeGet('fontSizes.2'), getBackgroundColor('primary'));
export var Link = styled(LinkElement).attrs(function () {
  return {
    color: 'text.primary',
    fontSize: 2,
    underlineOnHover: false
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var List = styled.ul(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  list-style-position: inside;\n  justify-content: space-around;\n  display: inline;\n  font-size: inherit;\n  line-height: normal;\n"])));
export var Title = styled(Heading)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: ", ";\n  padding-bottom: ", "px;\n  line-height: normal;\n  font-size: ", "px;\n\n  ", " {\n    font-size: ", "px;\n  }\n"])), function (props) {
  return getTextColor('base')(props);
}, themeGet('space.3'), themeGet('fontSizes.4'), getMediaQuery('medium'), themeGet('fontSizes.5'));