import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["titleAs", "items", "title"];
import React from 'react';
import { shape, string, arrayOf } from 'prop-types';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container, Title, Link, List, Item, Content } from './BlogLinkList.styles';
var BlogLinkList = function BlogLinkList(_ref) {
  var id = _ref.id,
    staticProps = _ref["static"];
  var handleClickLink = function handleClickLink(e, href) {
    e.preventDefault();
    routePush({
      path: href,
      spa: true
    });
  };
  var _staticProps$titleAs = staticProps.titleAs,
    titleAs = _staticProps$titleAs === void 0 ? 'h2' : _staticProps$titleAs,
    _staticProps$items = staticProps.items,
    items = _staticProps$items === void 0 ? [] : _staticProps$items,
    title = staticProps.title,
    props = _objectWithoutProperties(staticProps, _excluded);
  return Boolean(items == null ? void 0 : items.length) && React.createElement(Container, _extends({
    "data-testid": "link-list-container"
  }, props), React.createElement(Content, null, title && React.createElement(Title, {
    forwardedAs: titleAs,
    "data-testid": "link-list-title"
  }, title), React.createElement(List, {
    "data-testid": "link-list-ul"
  }, items == null ? void 0 : items.map(function (item, index) {
    return React.createElement(Item, {
      key: "".concat(id, "-").concat(index + 1),
      "data-testid": "link-list-text"
    }, React.createElement(Link, {
      alt: item.alt,
      href: item == null ? void 0 : item.href,
      onClick: function onClick(e) {
        return handleClickLink(e, item.href);
      }
    }, item.value));
  }))));
};
process.env.NODE_ENV !== "production" ? BlogLinkList.propTypes = {
  id: string,
  "static": shape({
    items: arrayOf(shape({
      alt: string,
      href: string.isRequired,
      value: string.isRequired
    })),
    title: string,
    titleAs: string
  }),
  structure: shape({
    asPath: string
  })
} : void 0;
BlogLinkList.defaultProps = {
  id: 'list',
  "static": {},
  structure: {}
};
BlogLinkList.ssr = true;
export default withLayoutProps(BlogLinkList);