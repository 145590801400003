import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["links", "title", "theme", "slug"];
import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Link from '@magalu/stereo-ui/atoms/Link';
import Text from '@magalu/stereo-ui/atoms/Text';
import { Instagram, WhatsApp, Twitter, LinkedIn, Facebook, Tiktok, Youtube } from '@magalu/stereo-ui-icons';
import { usePublisher } from '@magalu/mixer-publisher';
import { parseShareLinks } from './BlogShareActions.utils';
var BlogShareActions = function BlogShareActions(_ref) {
  var _structure$config;
  var data = _ref.data,
    structure = _ref.structure,
    _ref$static = _ref["static"],
    _ref$static$links = _ref$static.links,
    links = _ref$static$links === void 0 ? [] : _ref$static$links,
    title = _ref$static.title,
    _ref$static$theme = _ref$static.theme;
  _ref$static$theme = _ref$static$theme === void 0 ? {} : _ref$static$theme;
  var _ref$static$theme$ico = _ref$static$theme.iconColor,
    iconColor = _ref$static$theme$ico === void 0 ? 'background.dark' : _ref$static$theme$ico,
    _ref$static$theme$bac = _ref$static$theme.backgroundColor,
    backgroundColor = _ref$static$theme$bac === void 0 ? 'background.light' : _ref$static$theme$bac,
    slug = _ref$static.slug,
    props = _objectWithoutProperties(_ref$static, _excluded);
  var _ref2 = (data == null ? void 0 : data.blogPostContent) || {},
    _ref2$title = _ref2.title,
    postTitle = _ref2$title === void 0 ? '' : _ref2$title,
    seo = _ref2.seo;
  var domain = structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.domain;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var parsedLinks = parseShareLinks({
    domain: domain,
    title: (seo == null ? void 0 : seo.title) || postTitle,
    url: slug
  });
  var socialItems = links.map(function (shareItem) {
    return _extends({}, shareItem, {
      url: (parsedLinks == null ? void 0 : parsedLinks[shareItem.url]) || shareItem.url
    });
  });
  var handleShare = function handleShare(socialName) {
    publish('shareblog:click', {
      socialName: socialName
    });
  };
  var SocialIcons = {
    Facebook: React.createElement(Facebook, {
      width: 20,
      height: 20,
      color: iconColor,
      "data-testid": "blog-share-icon"
    }),
    Instagram: React.createElement(Instagram, {
      width: 20,
      height: 20,
      color: iconColor,
      "data-testid": "blog-share-icon"
    }),
    LinkedIn: React.createElement(LinkedIn, {
      width: 20,
      height: 20,
      color: iconColor,
      "data-testid": "blog-share-icon"
    }),
    Tiktok: React.createElement(Tiktok, {
      width: 20,
      height: 20,
      color: iconColor,
      "data-testid": "blog-share-icon"
    }),
    Twitter: React.createElement(Twitter, {
      width: 20,
      height: 20,
      color: iconColor,
      "data-testid": "blog-share-icon"
    }),
    WhatsApp: React.createElement(WhatsApp, {
      width: 20,
      height: 20,
      color: iconColor,
      "data-testid": "blog-share-icon"
    }),
    Youtube: React.createElement(Youtube, {
      width: 20,
      height: 20,
      color: iconColor,
      "data-testid": "blog-share-icon"
    })
  };
  return Boolean(links.length) && React.createElement(Flex, _extends({
    flexDirection: "column"
  }, props, {
    "data-testid": "blog-share-container"
  }), title && React.createElement(Text, {
    color: "inherit",
    fontWeight: "medium",
    textAlign: ['center', 'center', 'left'],
    fontSize: 0,
    "data-testid": "blog-share-title"
  }, title), React.createElement(Flex, {
    columnGap: 3,
    mt: 2
  }, socialItems == null ? void 0 : socialItems.map(function (_ref3) {
    var alt = _ref3.alt,
      icon = _ref3.icon,
      url = _ref3.url;
    return React.createElement(Link, {
      alt: alt,
      key: "share-actions-".concat(icon),
      href: url,
      target: "_blank"
    }, React.createElement(Flex, {
      width: 32,
      height: 32,
      borderRadius: "circle",
      bg: backgroundColor,
      justifyContent: "center",
      alignItems: "center",
      p: 1,
      "data-testid": "blog-share-icon-container",
      onClick: function onClick() {
        return handleShare(icon);
      }
    }, SocialIcons[icon]));
  })));
};
BlogShareActions.ssr = true;
process.env.NODE_ENV !== "production" ? BlogShareActions.propTypes = {
  data: shape({}),
  "static": shape({
    links: arrayOf(shape({
      alt: string,
      icon: string,
      url: string
    })),
    theme: shape({
      backgroundColor: string,
      iconColor: string
    }),
    title: string
  }),
  structure: shape({})
} : void 0;
BlogShareActions.defaultProps = {
  data: {
    blogPostContent: {}
  },
  "static": {},
  structure: {}
};
export default BlogShareActions;