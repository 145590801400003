import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import { uniqueId } from 'lodash';
import { List, Content, ListLabel, SubLinksContainer, SubLinks } from './BlogDropdownMenuDesk.styles';
var BlogDropdownMenuDesk = function BlogDropdownMenuDesk(_ref) {
  var linkGroups = _ref.linkGroups,
    onMenuItemClick = _ref.onMenuItemClick;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    openGroup = _useState2[0],
    setOpenGroup = _useState2[1];
  return React.createElement(Content, {
    "data-testid": "blog-content-group-item",
    onMouseLeave: function onMouseLeave() {
      return setOpenGroup(null);
    }
  }, !!(linkGroups == null ? void 0 : linkGroups.length) && React.createElement(List, null, linkGroups == null ? void 0 : linkGroups.map(function (_ref2) {
    var label = _ref2.label,
      linkList = _ref2.linkList;
    return React.createElement(ListLabel, {
      "data-testid": "blog-menu-group-item",
      key: "link-group-".concat(uniqueId(label)),
      fill: "primary",
      onMouseEnter: function onMouseEnter() {
        return setOpenGroup(label);
      }
    }, label, openGroup === label ? React.createElement(SubLinksContainer, {
      "data-testid": "blog-sub-container-link-item"
    }, linkList.map(function (item) {
      return React.createElement(SubLinks, {
        "data-testid": "blog-sub-link-item",
        key: "link-item-".concat(uniqueId(label), "-").concat(uniqueId(item.value)),
        href: item.href,
        alt: item.alt,
        onClick: function onClick(e) {
          return onMenuItemClick(e, item);
        }
      }, item.value);
    })) : null);
  })));
};
process.env.NODE_ENV !== "production" ? BlogDropdownMenuDesk.propTypes = {
  linkGroups: arrayOf(shape({
    label: string,
    linkList: arrayOf(shape({
      alt: string,
      href: string,
      value: string
    }))
  })),
  onMenuItemClick: func.isRequired
} : void 0;
BlogDropdownMenuDesk.defaultProps = {
  linkGroups: []
};
export default BlogDropdownMenuDesk;