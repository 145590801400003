import React from 'react';
import { arrayOf, shape, string, oneOf } from 'prop-types';

import Card from '@magalu/stereo-ui/atoms/Card';
import Text from '@magalu/stereo-ui/atoms/Text';
import Flex from '@magalu/stereo-ui/atoms/Flex';

import { routePush, parseDateTime } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
function VerticalList(_ref) {
  var posts = _ref.posts,
    cardTheme = _ref.cardTheme,
    titleAs = _ref.titleAs,
    viewport = _ref.viewport,
    customBasePath = _ref.customBasePath;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handlePostClick = function handlePostClick(categoryId, title, e, slug, postId) {
    e.preventDefault();
    var recomendationLayout = 'VerticalList';
    routePush({
      path: "".concat(customBasePath, "/blog-da-lu/c/").concat(slug, "/").concat(postId, "/"),
      spa: true
    });
    publish('blogpost:click', {
      categoryId: categoryId,
      recomendationLayout: recomendationLayout,
      title: title
    });
  };
  return React.createElement(Flex, {
    gap: 4,
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "space-between",
    "data-testid": "list-vertical-variation"
  }, posts == null ? void 0 : posts.map(function (_ref2) {
    var _image$urls, _parseDateTime;
    var id = _ref2.id,
      image = _ref2.image,
      title = _ref2.title,
      categoryId = _ref2.categoryId,
      categoryTitle = _ref2.categoryTitle,
      updatedAt = _ref2.updatedAt,
      slug = _ref2.slug;
    return React.createElement(Card, {
      key: id,
      as: "a",
      href: "".concat(customBasePath, "/blog-da-lu/c/").concat(slug, "/").concat(id, "/"),
      backgroundColor: cardTheme,
      enableHover: true,
      "data-testid": "list-vertical-card",
      gridTemplateAreas: ["'image' 'body'", "'image' 'body'", "'image body'"],
      gridTemplateColumns: ['1fr', '1fr', '140px 1fr'],
      gridTemplateRows: ['250px auto', '190px auto', 'unset'],
      height: ['auto', 'auto', '120px'],
      onClick: function onClick(e) {
        return handlePostClick(categoryId, title, e, slug, id);
      }
    }, React.createElement(Card.Image, {
      src: "".concat(image == null ? void 0 : (_image$urls = image.urls) == null ? void 0 : _image$urls["default"], "?ims=fit-in/{w}x"),
      alt: image.alt,
      objectFit: "cover",
      borderRadius: 6,
      srcSetDensity: viewport === 'mobile' ? [1, 1.5, 2] : [],
      width: viewport === 'mobile' ? 380 : 200
    }), React.createElement(Card.Body, {
      justifyContent: ['initial', 'initial', 'space-between'],
      padding: ['16px 14px', '16px 14px', 2]
    }, React.createElement(Card.Title, {
      forwardedAs: titleAs,
      truncate: true,
      lines: 2,
      color: "text.base",
      fontSize: [3, 3, 2]
    }, title), React.createElement(Card.Subtitle, {
      color: "text.base"
    }, React.createElement(Text, {
      fontWeight: "bold",
      color: "text.primary",
      display: "inline-block"
    }, categoryTitle), !!updatedAt && React.createElement(Text, {
      display: "inline-block"
    }, "\xA0\xA0 \u2022 \xA0\xA0", (_parseDateTime = parseDateTime(updatedAt)) == null ? void 0 : _parseDateTime.getShortMonthYear))));
  }));
}
process.env.NODE_ENV !== "production" ? VerticalList.propTypes = {
  cardTheme: string,
  customBasePath: string,
  posts: arrayOf(shape({})).isRequired,
  titleAs: string,
  viewport: oneOf(['mobile', 'desktop'])
} : void 0;
VerticalList.defaultProps = {
  cardTheme: 'background.light',
  customBasePath: '',
  titleAs: 'h3',
  viewport: 'mobile'
};
export default VerticalList;