import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import Strip from '@magalu/stereo-ui/atoms/Strip';
import { LogoBlogLu } from '@magalu/stereo-ui-icons';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import BlogDropdownMenuDesk from './BlogDropdownMenuDesk';
import { Content, Logo, BlogLinkEcommerce, Container } from './BlogHeaderDesk.styles';
var BlogHeaderDesk = function BlogHeaderDesk(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data;
  var _data$blogHeader = data.blogHeader,
    headerData = _data$blogHeader === void 0 ? {} : _data$blogHeader;
  var _ref2 = headerData || {},
    _ref2$LinksGroup = _ref2.LinksGroup,
    linksGroups = _ref2$LinksGroup === void 0 ? [] : _ref2$LinksGroup;
  var ecommerceLink = staticProps.ecommerceLink,
    logo = staticProps.logo;
  var _ref3 = (headerData == null ? void 0 : headerData.linkList) || [],
    linkList = _ref3.linkList;
  var handleMenuItemClick = function handleMenuItemClick(e, item) {
    e.preventDefault();
    routePush({
      path: item.href,
      spa: true
    });
  };
  var handleLogoClick = function handleLogoClick(e) {
    e.preventDefault();
    routePush({
      path: logo.url,
      spa: true
    });
  };
  return React.createElement(React.Fragment, null, React.createElement(Content, {
    "data-testid": "blog-header-desk-container"
  }, React.createElement(Container, null, React.createElement(Logo, {
    onClick: handleLogoClick,
    href: logo.url,
    role: "link",
    "data-testid": "desk-logo",
    "aria-label": "ir para p\xE1gina inicial"
  }, React.createElement(LogoBlogLu, {
    color: "text.light",
    width: 187,
    height: 50
  })), React.createElement(BlogLinkEcommerce, _extends({
    width: 230,
    height: 40
  }, ecommerceLink))), React.createElement(BlogDropdownMenuDesk, {
    linkList: linkList,
    linkGroups: linksGroups,
    onMenuItemClick: handleMenuItemClick
  })), React.createElement(Strip, {
    position: "relative"
  }));
};
process.env.NODE_ENV !== "production" ? BlogHeaderDesk.propTypes = {
  data: shape({
    blogHeader: shape({
      LinksGroup: arrayOf(shape({
        label: string,
        linkList: arrayOf(shape({
          alt: string,
          href: string,
          value: string
        }))
      }))
    })
  }),
  "static": shape({
    ecommerceLink: shape({
      href: string,
      label: string
    }).isRequired,
    logo: shape({
      url: string.isRequired
    }).isRequired
  })
} : void 0;
BlogHeaderDesk.defaultProps = {
  data: {},
  "static": {}
};
BlogHeaderDesk.ssr = true;
BlogHeaderDesk.displayName = 'BlogHeaderDesk';
export default withLayoutProps(BlogHeaderDesk);