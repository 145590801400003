import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["component", "backgroundColor", "cardColor", "disableBackground", "gutter", "displaySubtitle", "title"];
import React from 'react';
import { arrayOf, oneOf, shape, string } from 'prop-types';
import Link from '@magalu/stereo-ui/atoms/Link';
import Text from '@magalu/stereo-ui/atoms/Text';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { routePush } from '@magalu/mixer-utils';

import { Container, Content, Title, TitleContainer } from './BlogRecommendation.styles';

import { getVariation } from './Variations';
function BlogRecommendation(_ref) {
  var staticProps = _ref["static"],
    _ref$structure = _ref.structure,
    blogTheme = _ref$structure.theme,
    device = _ref$structure.device;
  var _staticProps$viewport = staticProps.viewport,
    viewport = _staticProps$viewport === void 0 ? device : _staticProps$viewport,
    columns = staticProps.columns,
    _staticProps$titleAs = staticProps.titleAs,
    titleAs = _staticProps$titleAs === void 0 ? 'h2' : _staticProps$titleAs,
    _staticProps$postTitl = staticProps.postTitleAs,
    postTitleAs = _staticProps$postTitl === void 0 ? 'h3' : _staticProps$postTitl,
    _staticProps$override = staticProps.overrideVariations,
    overrideVariations = _staticProps$override === void 0 ? {} : _staticProps$override,
    seeMoreLabel = staticProps.seeMoreLabel,
    basePath = staticProps.basePath,
    _staticProps$customBa = staticProps.customBasePath,
    customBasePath = _staticProps$customBa === void 0 ? '' : _staticProps$customBa;
  var recommendations = Array.isArray(staticProps == null ? void 0 : staticProps.recommendations) ? staticProps == null ? void 0 : staticProps.recommendations : [staticProps == null ? void 0 : staticProps.recommendations].filter(Boolean);
  var handleClick = function handleClick(e, url) {
    e.preventDefault();
    routePush({
      path: url,
      spa: true
    });
  };
  return recommendations.map(function (_ref2) {
    var blogCategoryId = _ref2.blogCategoryId,
      categoryId = _ref2.categoryId,
      parentCategoryId = _ref2.parentCategoryId,
      link = _ref2.link,
      title = _ref2.title,
      variation = _ref2.variation,
      theme = _ref2.theme,
      posts = _ref2.posts;
    var backgroundColorToken = theme.background,
      cardColorTheme = theme.card;
    var _getVariation = getVariation(variation, {
        backgroundColorToken: backgroundColorToken,
        cardColorTheme: cardColorTheme,
        overrideVariations: overrideVariations
      }),
      Recommendation = _getVariation.component,
      backgroundColor = _getVariation.backgroundColor,
      cardColor = _getVariation.cardColor,
      disableBackground = _getVariation.disableBackground,
      gutter = _getVariation.gutter,
      displaySubtitle = _getVariation.displaySubtitle,
      _getVariation$title = _getVariation.title;
    _getVariation$title = _getVariation$title === void 0 ? {} : _getVariation$title;
    var _getVariation$title$f = _getVariation$title.fontWeight,
      fontWeight = _getVariation$title$f === void 0 ? '500' : _getVariation$title$f,
      variationProps = _objectWithoutProperties(_getVariation, _excluded);
    var isSeeMore = Boolean(link || blogCategoryId);
    var isAbsoluteLink = Boolean(link == null ? void 0 : link.startsWith('/blog-da-lu'));
    var categoryUrl = parentCategoryId ? "".concat(basePath, "/s/").concat(parentCategoryId == null ? void 0 : parentCategoryId.toLowerCase(), "/").concat(categoryId == null ? void 0 : categoryId.toLowerCase(), "/").concat(link, "/").concat(blogCategoryId, "/") : "".concat(basePath, "/l/").concat(categoryId == null ? void 0 : categoryId.toLowerCase(), "/").concat(link, "/").concat(blogCategoryId, "/");
    var url = "".concat(customBasePath).concat(isAbsoluteLink ? link : categoryUrl);
    return Boolean(posts) && Boolean(posts.length) && React.createElement(Container, _extends({
      key: title,
      gutter: gutter,
      disableBackground: disableBackground,
      backgroundColor: backgroundColor
    }, variationProps), React.createElement(Content, _extends({
      backgroundTheme: disableBackground ? 'transparent' : backgroundColor
    }, blogTheme), React.createElement(TitleContainer, null, Boolean(title) && React.createElement(Title, {
      forwardedAs: titleAs,
      children: title,
      fontSize: 5,
      fontWeight: fontWeight,
      lineHeight: 1,
      mb: 4,
      color: "inherit"
    }), Boolean(isSeeMore) && React.createElement(Link, {
      href: url,
      onClick: function onClick(e) {
        return handleClick(e, url);
      },
      role: "link",
      "data-testid": "see-more-link",
      color: "inherit"
    }, React.createElement(Text, {
      "data-testid": "see-more-label",
      color: "inherit",
      fontWeight: "bold",
      fontSize: 1
    }, seeMoreLabel))), React.createElement(Recommendation, {
      columns: columns,
      posts: posts,
      cardTheme: cardColor,
      viewport: viewport,
      titleAs: postTitleAs,
      displaySubtitle: displaySubtitle,
      recomendationTitle: title,
      customBasePath: customBasePath
    })));
  });
}
process.env.NODE_ENV !== "production" ? BlogRecommendation.propTypes = {
  postTitleAs: string,
  "static": shape({
    customBasePath: string,
    overrideVariations: shape({}),
    recommendations: arrayOf(shape({
      categoryId: string,
      id: string,
      link: string,
      postIds: arrayOf(string),
      theme: shape({
        background: string,
        card: string
      }),
      title: string,
      variation: string
    })),
    seeMoreLabel: string,
    titleAs: string,
    viewport: oneOf(['mobile', 'desktop'])
  }),
  structure: shape({})
} : void 0;
BlogRecommendation.defaultProps = {
  data: {},
  "static": {
    customBasePath: '',
    recommendations: []
  },
  structure: {}
};
BlogRecommendation.ssr = true;
export default withLayoutProps(BlogRecommendation);