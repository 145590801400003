import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import Link from '@magalu/stereo-ui/atoms/Link';
import BlogEcommerceLink from '../BlogEcommerceLink';
export var BlogLinkEcommerce = styled(BlogEcommerceLink)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border: 1px solid white;\n  border-radius: 12px;\n  overflow: hidden;\n  justify-self: right;\n"])));
export var Container = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n"])));
export var Content = styled(Box).attrs(function () {
  return {
    bg: 'primary.base',
    color: 'text.light'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 30px;\n  flex-direction: column;\n  align-items: center;\n  padding: 0 80px;\n  padding-top: 35px;\n  position: relative;\n"])));
export var Logo = styled(Link)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  justify-self: left;\n"])));