import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import Link from '@magalu/stereo-ui/atoms/Link';
import { getTextColor, getBackgroundColor, themeGet } from '@magalu/stereo-ui-styles';
import Flex from '@magalu/stereo-ui/atoms/Flex';
export var Content = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n"])));
export var List = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-direction: row;\n  justify-content: center;\n"])));
export var ListLabel = styled(Box).attrs(function () {
  return {
    fontWeight: 'bold'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 10px 16px;\n  margin-right: 10px;\n  cursor: pointer;\n  &:hover {\n    color: ", ";\n    background: ", ";\n    border-top-left-radius: 12px;\n    border-top-right-radius: 12px;\n  }\n"])), function (props) {
  return getTextColor('primary')(props);
}, function (props) {
  return getBackgroundColor('light')(props);
});
export var SubLinks = styled(Link).attrs(function () {
  return {
    color: 'text.base',
    fontWeight: 'bold',
    marginRight: '20px'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  box-shadow: ", ";\n  padding-bottom: 20px;\n  cursor: pointer;\n  &:hover {\n    text-decoration: none;\n    color: ", ";\n  }\n"])), function (props) {
  return themeGet('boxShadows.5')(props);
}, function (props) {
  return getTextColor('primary')(props);
});
export var SubLinksContainer = styled(Box).attrs(function () {
  return {
    backgroundColor: 'background.light'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  top: calc(100% - 8px);\n  left: 0;\n  width: 100%;\n  position: absolute;\n  z-index: ", ";\n  display: flex;\n  padding: 32px 100px;\n  flex-wrap: wrap;\n  gap: 30px;\n"])), function (props) {
  return themeGet('zIndices.4')(props);
});