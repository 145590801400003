import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["backgroundColor", "href", "label", "textColor"];
import React from 'react';
import { string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import Link from '@magalu/stereo-ui/atoms/Link';
import withLayoutProps from '../../../hocs/withLayoutProps';
var BlogEcommerceLink = function BlogEcommerceLink(_ref) {
  var backgroundColor = _ref.backgroundColor,
    href = _ref.href,
    label = _ref.label,
    textColor = _ref.textColor,
    props = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Link, _extends({
    "data-testid": "blog-ecommerce-link",
    href: href
  }, props), React.createElement(Flex, {
    "data-testid": "blog-ecommerce-link-container",
    alignItems: "center",
    justifyContent: "center",
    bg: backgroundColor,
    p: 3,
    borderRadius: "12px",
    width: 230,
    height: 48,
    columnGap: 2
  }, React.createElement(Text, {
    "data-testid": "blog-ecommerce-link-label",
    color: textColor,
    fontWeight: "bold",
    fontSize: 1
  }, label), React.createElement(Icon, {
    name: "OpenLink",
    color: textColor
  })));
};
process.env.NODE_ENV !== "production" ? BlogEcommerceLink.propTypes = {
  backgroundColor: string,
  href: string.isRequired,
  label: string.isRequired,
  textColor: string
} : void 0;
BlogEcommerceLink.defaultProps = {
  backgroundColor: 'primary.base',
  textColor: 'text.light'
};
export default withLayoutProps(BlogEcommerceLink);