import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["__typename"],
  _excluded2 = ["__typename"];
var parseBundles = function parseBundles(bundles) {
  return bundles.map(function (_ref) {
    var id = _ref.id,
      quantity = _ref.quantity,
      price = _ref.price,
      _ref$dimensions = _ref.dimensions,
      __typename = _ref$dimensions.__typename,
      dimensions = _objectWithoutProperties(_ref$dimensions, _excluded);
    return {
      dimensions: _extends({}, dimensions),
      id: id,
      price: price,
      quantity: quantity
    };
  });
};
var parseShippingVariables = function parseShippingVariables() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref2$pageName = _ref2.pageName,
    pageName = _ref2$pageName === void 0 ? '' : _ref2$pageName,
    _ref2$product = _ref2.product,
    product = _ref2$product === void 0 ? {} : _ref2$product,
    _ref2$structure = _ref2.structure,
    structure = _ref2$structure === void 0 ? {} : _ref2$structure,
    _ref2$customZipCode = _ref2.customZipCode,
    customZipCode = _ref2$customZipCode === void 0 ? '' : _ref2$customZipCode;
  var _product$price = product.price;
  _product$price = _product$price === void 0 ? {} : _product$price;
  var fullPrice = _product$price.fullPrice,
    listPrice = _product$price.price,
    currency = _product$price.currency,
    exchangeRate = _product$price.exchangeRate,
    idExchangeRate = _product$price.idExchangeRate,
    originalPriceForeign = _product$price.originalPriceForeign,
    _product$seller = product.seller;
  _product$seller = _product$seller === void 0 ? {} : _product$seller;
  var _product$seller$id = _product$seller.id,
    sellerId = _product$seller$id === void 0 ? '' : _product$seller$id,
    _product$seller$categ = _product$seller.category,
    sellerCategory = _product$seller$categ === void 0 ? '1p' : _product$seller$categ,
    _product$seller$sku = _product$seller.sku,
    sellerSku = _product$seller$sku === void 0 ? '' : _product$seller$sku,
    _product$variationId = product.variationId,
    variationId = _product$variationId === void 0 ? '' : _product$variationId,
    _product$type = product.type,
    type = _product$type === void 0 ? '' : _product$type,
    _product$bundles = product.bundles,
    bundles = _product$bundles === void 0 ? [] : _product$bundles,
    _product$dimensions = product.dimensions;
  _product$dimensions = _product$dimensions === void 0 ? {} : _product$dimensions;
  var __typename = _product$dimensions.__typename,
    dimensions = _objectWithoutProperties(_product$dimensions, _excluded2);
  var price = fullPrice || listPrice;
  var _structure$cookies = structure.cookies;
  _structure$cookies = _structure$cookies === void 0 ? {} : _structure$cookies;
  var _structure$cookies$lo = _structure$cookies.location;
  _structure$cookies$lo = _structure$cookies$lo === void 0 ? {} : _structure$cookies$lo;
  var _structure$cookies$lo2 = _structure$cookies$lo.zipCode,
    zipcode = _structure$cookies$lo2 === void 0 ? '' : _structure$cookies$lo2,
    _structure$cookies$ac = _structure$cookies.accountData;
  _structure$cookies$ac = _structure$cookies$ac === void 0 ? {} : _structure$cookies$ac;
  var _structure$cookies$ac2 = _structure$cookies$ac.id,
    clientId = _structure$cookies$ac2 === void 0 ? '' : _structure$cookies$ac2,
    _structure$config = structure.config,
    config = _structure$config === void 0 ? {} : _structure$config,
    _structure$route = structure.route;
  _structure$route = _structure$route === void 0 ? {} : _structure$route;
  var _structure$route$cate = _structure$route.categoryId,
    categoryId = _structure$route$cate === void 0 ? '' : _structure$route$cate,
    _structure$route$subC = _structure$route.subCategoryId,
    subcategoryId = _structure$route$subC === void 0 ? '' : _structure$route$subC,
    _structure$route$part = _structure$route.partner_id,
    partnerId = _structure$route$part === void 0 ? '0' : _structure$route$part;
  var parsePartnerId = function parsePartnerId(partnerIdParam) {
    if (Array.isArray(partnerIdParam)) return partnerIdParam[0];
    return partnerIdParam;
  };
  return {
    metadata: {
      categoryId: categoryId,
      clientId: clientId,
      organizationId: 'magazine_luiza',
      pageName: pageName,
      partnerId: parsePartnerId(partnerId),
      salesChannelId: config.salesChannelId,
      sellerId: sellerId,
      subcategoryId: subcategoryId
    },
    product: _extends({
      currency: currency,
      dimensions: _extends({}, dimensions),
      exchangeRate: parseFloat(exchangeRate),
      id: sellerCategory === '1p' ? variationId : sellerSku,
      idExchangeRate: String(idExchangeRate),
      originalPriceForeign: parseFloat(originalPriceForeign),
      price: parseFloat(price),
      quantity: 1,
      type: type
    }, type === 'bundle' && (bundles == null ? void 0 : bundles.length) > 0 && {
      bundles: parseBundles(bundles)
    }),
    zipcode: customZipCode || zipcode
  };
};
export default parseShippingVariables;