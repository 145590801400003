export default {
  button: {
    actionLabel: 'calcular',
    changeLabel: 'alterar',
    maxCityLength: 24,
    placeholder: 'Calcular frete e prazo'
  },
  error: {
    NOT_AVAILABLE: 'not_available'
  },
  labels: {
    conventional: {
      complement: 'após o pagamento confirmado',
      "default": 'Entrega em até 1h',
      plural: 'Receber em até {X} dias úteis',
      singular: 'Receber em até 1 dia útil'
    },
    store_pickup: {
      complement: 'após o pagamento confirmado',
      "default": 'Retirar na loja em 2h',
      plural: 'Retirar na loja em até {X} dias úteis',
      singular: 'Retirar na loja em até 1 dia útil'
    }
  }
};