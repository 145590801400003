import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import Box from '@magalu/stereo-ui/atoms/Box';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import { getMediaQuery } from '@magalu/stereo-ui-styles';
export var Container = styled(Box).attrs(function (_ref) {
  var backgroundColor = _ref.backgroundColor,
    disableBackground = _ref.disableBackground;
  return {
    backgroundColor: disableBackground ? 'transparent' : backgroundColor
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  clear: both;\n"])));
export var Content = styled(Box)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  max-width: 1366px;\n  margin: 0 auto;\n"])));
export var Title = styled(Heading)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var TitleContainer = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: baseline;\n  justify-content: space-between;\n  ", " {\n    justify-content: flex-start;\n  }\n  gap: 24px;\n"])), getMediaQuery('small'));