import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import shuffle from 'lodash/shuffle';

function fitLinksToCharacterLimit(list, maxCharacters) {
  return list.reduce(function (acc, link) {
    var links = acc.links,
      totalCharacters = acc.totalCharacters;
    if (totalCharacters + link.value.length <= maxCharacters - 1) {
      return {
        links: [].concat(_toConsumableArray(links), [link]),
        totalCharacters: totalCharacters + link.value.length + 1
      };
    }
    return acc;
  }, {
    links: [],
    totalCharacters: 0
  }).links;
}
var randomLinksHeader = function randomLinksHeader(linksHeader, listSize, maxChars) {
  var links = linksHeader.links,
    title = linksHeader.title;
  var randomLinks = shuffle(links);
  var slicedList = randomLinks.slice(0, listSize);
  return fitLinksToCharacterLimit(slicedList, maxChars - title.length);
};
export default randomLinksHeader;